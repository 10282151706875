<template>
  <div class="row">
    <div class="col">
      <h4>CONFIGURAR MENU DE ODONTOGRAMA</h4>
      <div class="form-group form-inline">
        <select class="custom-select" v-model="group">
          <option v-for="(l, index) in Groups" :key="index" :value="index">{{
            l
          }}</option>
        </select>
      </div>
      <table class="table table-responsive table-bordered table-sm">
        <tbody>
          <tr v-for="(l, i) in list" :key="i">
            <td>
              <IconState :status="l.status"></IconState>
            </td>
            <td>{{ l.name }}</td>
          </tr>
        </tbody>
      </table>

      <div class="mt-2">
        <button
          class="btn btn-primary mr-1"
          @click="list.push({ group: group })"
        >
          Agregar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// import FormMenu from "./FormMenu";
import Groups from "../groups.js";
import { DentalClinicService } from "../../service";
import IconState from "./IconState";

export default {
  components: {
    // FormMenu,
    IconState
  },
  data: () => ({
    list: [],
    Groups,
    reg: {},
    group: 1,
    menu_id: undefined
  }),
  watch: {
    $route() {
      this.list = [];
      this.setMenuFromRoute();
      this.getList();
    },
    group() {
      if (!this.menu_id) {
        this.getList();
      }
    }
  },
  created() {
    this.setMenuFromRoute();
    this.getList();
  },
  methods: {
    setMenuFromRoute() {
      if (this.$route.query.menu_id) {
        this.menu_id = this.$route.query.menu_id;
      } else {
        this.menu_id = this.$route.query.menu_id;
      }
    },
    getList() {
      DentalClinicService.getOdontogramMenu({
        menu_id: this.menu_id,
        all: 1,
        group: this.menu_id ? undefined : this.group
      }).then(res => {
        this.list = res;
      });
    }
  }
};
</script>

<style></style>
