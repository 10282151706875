<template>
  <div class="app-odo-t-m-icon-container">
    <div class="app-odo-t-m-icon" :class="icon">&nbsp;</div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    status: {}
  },

  data: () => ({
    //
  }),

  computed: {
    ...mapState({
      list: s => s.config.dental.odontogram.teeth_status_list
    }),
    icon() {
      return this.status ? this.list[this.status].class_icon : "";
    }
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  }
};
</script>

<style></style>
